import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Sw from "./sw";
import firebase from "firebase/app";
import TextField from "@material-ui/core/TextField";

export default function Stopwatch(props) {
  const firestore = props.firestore;
  const messagesRef = firestore.collection("myvideovault");
  let query = messagesRef.where("type", "==", "stopwatch");
  query = query.where("uid", "==", props.auth.currentUser.uid);

  const [messages] = useCollectionData(query, { idField: "id" });

  function stop(stoprops) {
    firestore
      .doc("myvideovault/" + messages[0]["id"])
      .update({ active: false });
    let duration = Math.floor(Date.now() / 1000) - messages[0].start.seconds;
    messagesRef.add({
      name: messages[0].name,
      start: messages[0].start,
      uid: props.auth.currentUser.uid,
      duration,
      type: "record",
    });
  }

  function start(props) {
    let value = document.getElementById("name").value;
    firestore.doc("myvideovault/" + messages[0]["id"]).update({
      active: true,
      start: firebase.firestore.FieldValue.serverTimestamp(),
      name: value,
    });
  }

  function Active(props) {
    if (messages[0].start) {
      let time = Math.floor(Date.now() / 1000) - messages[0].start.seconds;

      console.log(time);
      var hr = ~~(time / 3600);
      var min = ~~((time % 3600) / 60);
      var sec = time % 60;
      while (sec < 0){
        sec++;
      }
      return <Sw time={{ s: sec, m: min, h: hr }} stop={() => stop()} />;
    }
    return null;
  }

  function Unactive(props) {
    return (
      <>
        <TextField id="name" label="Task Name" type="text" />
        <button onClick={() => start()}>Start</button>
      </>
    );
  }

  function Bar(props) {
    if (messages) {
      if (messages[0].active) {
        return <Active />;
      } else {
        return <Unactive />;
      }
    } else {
      return null;
    }
  }

  return (
    <div>
      <Bar />
    </div>
  );
}
