import React from "react";


import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import SimpleTabs from "./pages/tabs";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { styled } from '@material-ui/core/styles';

firebase.initializeApp({
  apiKey: "AIzaSyCuKYUPZCIrMI2Wf9sKYBdboudPObHe0Ms",
  authDomain: "projeracker.firebaseapp.com",
  projectId: "projeracker",
  storageBucket: "projeracker.appspot.com",
  messagingSenderId: "433928988712",
  appId: "1:433928988712:web:65cbe019d2262f0967ed1d",
  measurementId: "G-QQ8S2R6P1Z",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);
  const darkTheme = createMuiTheme({
    palette: {
      type: "dark",
    },
  });
  const useStyles = makeStyles((theme) => ({
    big: {
      width: "100%",
      height: "100vh",
      "background-color": darkTheme.palette.background.default,
    },
  }));
  const classes = useStyles();
  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.big}>
        <section>{user ? <SimpleTabs auth={auth} firestore={firestore} /> : <SignIn />}</section>
      </div>
    </ThemeProvider>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  const MyButton = styled(Button)({
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  });
  return <MyButton onClick={signInWithGoogle}>Sign in with Google</MyButton>;
}


export default App;
