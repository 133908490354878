import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import moment from "moment-timezone";
import Loading from "./loading";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PaymentModal from "./paymentadd";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function Payments(props) {
  const firestore = props.firestore;
  const messagesRef = firestore.collection("myvideovault");
  const payments = firestore
    .collection("myvideovault")
    .doc("report")
    .collection(props.auth.currentUser.uid)
    .doc("paid");
  let query = messagesRef.where("type", "==", "payment");
  query = query
    .where("uid", "==", props.auth.currentUser.uid)
    .orderBy("received", "desc");
  const [messages] = useCollectionData(query, { idField: "id" });

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [old, setOld] = React.useState(messages);
  function Jtable() {
    if (messages) {
      if (messages !== old) {
        setOld(messages);
        setLoading(false);
      }
      return (
        <TableBody>
          {messages.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {moment.unix(row.received.seconds).format("MMMM Do, YYYY")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.hours + " Hours"}
              </StyledTableCell>
              <StyledTableCell align="right">${row.amount}</StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  onClick={() => deleterecord(row.id, row.hours, row.amount)}
                >
                  Delete
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      );
    } else {
      return null;
    }
  }

  return (
    <div style={{ height: "calc(100vh - 48px)" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="right">Hours</StyledTableCell>
              <StyledTableCell align="right">Recieved</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>

          <Jtable />
        </Table>
      </TableContainer>
      {open && <PaymentModal toclose={setOpen} create={createrecord} />}
      {loading && <Loading />}
      <Fab
        color="primary"
        aria-label="add"
        style={{ bottom: 50, right: 50, position: "fixed" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
  async function deleterecord(id, hours, amount) {
    setLoading(true);
    let stuff = await payments.get();
    let lala = stuff.data();
    lala["amount"] -= amount;
    let seconds = hours * 60 * 60;
    lala["seconds"] -= seconds;
    await payments.set(lala);
    await messagesRef.doc(id).delete();
  }

  async function createrecord(insert) {
    let stuff = await payments.get();
    let lala = stuff.data();
    lala["amount"] += insert.amount;
    let seconds = insert.hours * 60 * 60;
    lala["seconds"] += seconds;
    payments.set(lala);
    const t = firebase.firestore.Timestamp.fromDate(
      new Date(insert.received.valueOf())
    );
    setOpen(false);
    messagesRef.add({
      received: t,
      uid: props.auth.currentUser.uid,
      hours: insert.hours,
      amount: insert.amount,
      type: "payment",
    });
  }
}
