import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import PriceModal from "./addprice";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImportModal from "./Import";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Settings(props) {
  const [open, setOpen] = React.useState(false);
  const [twoOpen, setTwoOpen] = React.useState(false);
  const firestore = props.firestore;
  const messagesRef = firestore
    .collection("myvideovault")
    .doc("settings")
    .collection("pricepoints");
  let query = messagesRef.orderBy(firebase.firestore.FieldPath.documentId());
  const [messages] = useCollectionData(query, { idField: "id" });

  if (messages) {
    return (
      <div style={{ height: "calc(100vh - 48px)" }}>
        <TableContainer style={{ maxWidth: 500 }} component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Start</StyledTableCell>
                <StyledTableCell align="left">Price</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {messages.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="left">
                    {row.start &&
                      moment.unix(row.start.seconds).format("MMMM Do, YYYY")}
                    {!row.start && "Starting Rate"}
                  </StyledTableCell>
                  <StyledTableCell align="left">${row.price}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {open && (
          <PriceModal
            price={messages[messages.length - 1].price}
            start={
              messages[messages.length - 1].start
                ? messages[messages.length - 1].start.seconds
                : 0
            }
            toclose={setOpen}
            create={createrecord}
          />
        )}
        {twoOpen && (
          <ImportModal toclose={setTwoOpen} firestore={props.firestore} />
        )}
        <Fab
          color="primary"
          aria-label="add"
          style={{ bottom: 50, left: 50, position: "fixed" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="add"
          style={{ bottom: 50, right: 50, position: "fixed" }}
          onClick={() => {
            setTwoOpen(true);
          }}
        >
          <CloudUploadIcon />
        </Fab>
      </div>
    );
  } else {
    return null;
  }

  async function createrecord(stuff) {
    const t = firebase.firestore.Timestamp.fromDate(
      new Date(stuff.start.valueOf())
    );
    let prev = messages[messages.length - 1].id;
    let old = messages[messages.length - 1].price;
    setOpen(false);
    await messagesRef
      .doc((parseInt(messages[messages.length - 1].id) + 1).toString())
      .set({
        start: t,
        price: parseFloat(stuff.price),
      });
    const admins = await firestore.collection("admins").get();
    admins.forEach((doc) => {
      changeUser(doc.id, t, prev, old, stuff.price);
    });
  }

  async function changeUser(user, date, id, old, price) {
    const messagesR = firestore.collection("myvideovault");
    let query = messagesR
      .where("type", "==", "record")
      .where("start", ">=", date);
    query = query.where("uid", "==", user).orderBy("start", "desc");
    let law = await query.get();
    let duration = 0;
    law.forEach((doc) => {
      duration += doc.data().duration;
    });

    let stuff = await firestore
      .collection("myvideovault")
      .doc("report")
      .collection(user)
      .doc("totals")
      .get();

    let lol = await stuff.data();
    lol[id][0] -= duration;
    lol[id][1] = Math.ceil((lol[id][0] / 60 / 60) * old * 100) / 100;
    lol[parseInt(id) + 1] = [
      duration,
      Math.ceil((duration / 60 / 60) * price * 100) / 100,
    ];
    firestore
      .collection("myvideovault")
      .doc("report")
      .collection(user)
      .doc("totals")
      .set(lol);
  }
}
