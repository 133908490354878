import React, { useEffect } from "react";
import moment from "moment";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark as dark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export default function Report(props) {
  const [calc, setCalc] = React.useState(false);
  const [data, setData] = React.useState([]);

  let firestore = props.firestore;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (calc) {
    return (
      <SyntaxHighlighter language="json" style={dark}>
        {JSON.stringify(data, null, 2)}
      </SyntaxHighlighter>
    );
  } else {
    return null;
  }

  async function fetchData() {
    const admins = await firestore.collection("admins").get();
    let users = [];
    let num = 0;
    admins.forEach((doc) => {
      let user = {
        name: doc.data().name,
        id: doc.id,
      };
      num++;
      users.push(user);
    });
    let invoice = [];
    for (let i = 0; i < num; i++) {
      let paid = await getHourly(users[i].id);
      let info = await getOwed(users[i].id, paid);
      let user = {
        "Employee Name": users[i].name,
        "Average Rate": paid.rate,
        Invoice: info.invoice,
        Bonus: info.bonus,
      };
      invoice.push(user);
    }
    invoice = calculateTotals(invoice);
    setData(invoice);
    setCalc(true);
  }

  async function getHourly(id) {
    const timeslots = await firestore
      .collection("myvideovault")
      .doc("report")
      .collection(id)
      .doc("paid")
      .get();
    let lol = timeslots.data();
    let dollars = lol.amount;
    let seconds = lol.seconds;
    let value = {};
    value.dollars = dollars;
    value.seconds = seconds;
    if (seconds === 0) {
      value.rate = 0;
    } else {
      value.rate = Math.round((dollars / (seconds / 60 / 60)) * 100) / 100;
    }
    return value;
  }

  async function getOwed(id, paid) {
    let val = {};
    let seconds = paid.seconds;
    let amount = paid.dollars;
    const timeslots = await firestore
      .collection("myvideovault")
      .doc("settings")
      .collection("pricepoints")
      .get();
    let hourly = {};
    let count = 0;
    timeslots.forEach((doc) => {
      hourly[doc.id] = doc.data();
      count++;
    });

    const totals = await firestore
      .collection("myvideovault")
      .doc("report")
      .collection(id)
      .doc("totals")
      .get();
    let lol = totals.data();
    for (let i = 0; i < count; i++) {
      console.log(seconds);
      let current = {};
      let start = "Since ";
      if (hourly[i].start) {
        start += moment.unix(hourly[i].start.seconds).format("MMMM Do, YYYY");
      } else {
        start += "Project Start";
      }

      current.start = start;
      current.worked = lol[i][0];
      current.rate = hourly[i].price;
      current.owed =
        Math.ceil(current.rate * (current.worked / 60 / 60) * 100) / 100;

      if (seconds >= current.worked) {
        seconds -= current.worked;
        amount -= current.owed;
        continue;
      }
      if (seconds > 0) {
        current.worked -= seconds;
        current.owed =
          Math.ceil(current.rate * (current.worked / 60 / 60) * 100) / 100;
        amount -= Math.ceil(current.rate * (seconds / 60 / 60) * 100) / 100;
        seconds = 0;
      }

      val[i] = current;
    }
    return { invoice: val, bonus: amount };
  }

  function calculateTotals(invoice) {
    let max = calcMaxBonus(invoice);
    invoice = updateBonus(invoice, max);
    for (let i = 0; i < invoice.length; i++) {
      invoice[i]["Bonus Difference"] = invoice[i].Bonus;
      invoice[i]["Total Owed"] = invoice[i].Bonus + sumOwed(invoice[i].Invoice);
      delete invoice[i].Bonus;
    }
    return invoice;
  }

  function calcMaxBonus(invoice) {
    let bonus = 0;
    for (let i = 0; i < invoice.length; i++) {
      bonus = Math.max(bonus, invoice[i].Bonus);
    }
    return bonus;
  }

  function updateBonus(invoice, max) {
    for (let i = 0; i < invoice.length; i++) {
      invoice[i].Bonus = max - invoice[i].Bonus;
    }
    return invoice;
  }

  function sumOwed(invoice) {
    let owed = 0;
    for (var k in invoice) {
      owed += invoice[k].owed;
    }
    return owed;
  }
}
