import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import moment from "moment";
import CsvParse from "@vtex/react-csv-parse";

import LinearProgress from "@material-ui/core/LinearProgress";
import firebase from "firebase/app";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "90%",
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40%",
  },
  cancel: {
    height: "30px",
    float: "left",
  },
  submit: {
    height: "30px",
    float: "right",
  },
  butgroup: {
    width: "90%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function ImportModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const firestore = props.firestore;
  const messagesRef = firestore.collection("myvideovault");

  const [loading, setLoading] = React.useState(false);
  const handleClose = () => {
    if (!loading) {
      props.toclose(false);
    }
  };
  const handleData = (data) => {
    setLoading(true);
    reformatData(data);
  };

  const keys = [
    "Project",
    "Client",
    "Description",
    "Task",
    "User",
    "Email",
    "Tags",
    "Billable",
    "Start Date",
    "Start Time",
    "End Date",
    "End Time",
    "Duration (h)",
    "Duration (decimal)",
    "Billable Rate (USD)",
    "Billable Amount (USD)",
  ];

  //const keys = ["Project"];

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 style={{ color: "white", textAlign: "center" }}>
        Import CSV from Clockify
      </h2>
      <br />

      <br />
      {!loading && (
        <div className={classes.butgroup}>
          <Button className={classes.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <CsvParse
            keys={keys}
            onDataUploaded={handleData}
            render={(onChange) => (
              <Button className={classes.submit} component="label">
                Upload File
                <input type="file" hidden onChange={onChange} />
              </Button>
            )}
          />
        </div>
      )}
      {loading && <LinearProgress />}
    </div>
  );

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );

  async function reformatData(data) {
    const admins = await firestore.collection("admins").get();
    let members = {};
    admins.forEach((doc) => {
      members[doc.data().email] = {};
      members[doc.data().email].id = doc.id;
    });
    members = await getTotals(members);
    const timeslots = await firestore
      .collection("myvideovault")
      .doc("settings")
      .collection("pricepoints")
      .get();
    let life = {};
    let count = 0;
    timeslots.forEach((doc) => {
      life[doc.id] = doc.data();
      count++;
    });
    for (var k in data) {
      members = await createrecord(
        {
          name: data[k]["Description"],
          start: Date.parse(
            data[k]["Start Date"] + " " + data[k]["Start Time"]
          ),
          duration: moment.duration(data[k]["Duration (h)"]),
          uid: members[data[k]["Email"]].id,
          email: data[k]["Email"],
        },
        life,
        count,
        members
      );
    }
    setTotals(members);
    props.toclose(false);
  }

  async function getTotals(members) {
    for (var i in members) {
      let totals = firestore
        .collection("myvideovault")
        .doc("report")
        .collection(members[i].id)
        .doc("totals");
      let lol = await totals.get();
      lol = lol.data();
      members[i].totals = lol;
    }
    return members;
  }

  async function createrecord(insert, life, count, stuff) {
    const t = firebase.firestore.Timestamp.fromDate(new Date(insert.start));

    let val = await findId(t.seconds, life, count);
    stuff[insert.email]["totals"][val["id"]][0] += insert.duration.asSeconds();
    stuff[insert.email]["totals"][val["id"]][1] =
      Math.ceil(
        (stuff[insert.email]["totals"][val["id"]][0] / 60 / 60) *
          val["price"] *
          100
      ) / 100;

    messagesRef.add({
      name: insert.name,
      start: t,
      uid: insert.uid,
      duration: insert.duration.asSeconds(),
      type: "record",
    });
    return stuff;
  }

  async function findId(t, life, count) {
    let val = {};
    for (let i = 0; i < count; i++) {
      if (life[i].start) {
        if (t < life[i].start.seconds) {
          break;
        }
      }
      val["id"] = i;
      val["price"] = life[i].price;
    }
    return val;
  }

  async function setTotals(members) {
    for (let k in members) {
      let totals = firestore
        .collection("myvideovault")
        .doc("report")
        .collection(members[k].id)
        .doc("totals");
      await totals.set(members[k].totals);
    }
    return true;
  }
}
