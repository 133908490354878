import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import moment from "moment-timezone";
import SimpleModal from "./modal";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Loading from "./loading";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const pageSize = 15;
  const firestore = props.firestore;
  const messagesRef = firestore.collection("myvideovault");
  let totals = firestore
    .collection("myvideovault")
    .doc("report")
    .collection(props.auth.currentUser.uid)
    .doc("totals");
  let query = messagesRef.where("type", "==", "record");
  const ref = query.where("uid", "==", props.auth.currentUser.uid);
  query = ref.orderBy("start", "desc").limit(pageSize);
  const [messages] = useCollectionData(query, { idField: "id" });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [insert, setInsert] = React.useState({});
  const [old, setOld] = React.useState(messages);

  function toHMMSS(seconin) {
    var sec_num = parseInt(seconin, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  function Jtable() {
    if (messages) {
      if (messages !== old) {
        setOld(messages)
        setLoading(false);
      }
      return (
        <TableBody>
          {messages.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">
                {moment
                  .unix(row.start.seconds)
                  .format("MMMM Do, YYYY h:mm:ss A")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {moment
                  .unix(row.start.seconds)
                  .add(row.duration, "seconds")
                  .format("MMMM Do, YYYY h:mm:ss A")}
              </StyledTableCell>
              <StyledTableCell align="right">
                {toHMMSS(row.duration)}
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  onClick={() => {
                    setInsert({
                      new: false,
                      name: row.name,
                      start: moment.unix(row.start.seconds),
                      end: moment
                        .unix(row.start.seconds)
                        .add(row.duration, "seconds"),
                      duration: moment.duration(row.duration, "seconds"),
                      id: row.id,
                    });
                    setOpen(true);
                  }}
                >
                  Edit
                </Button>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Button
                  onClick={() =>
                    deleterecord(row.id, row.start.seconds, row.duration)
                  }
                >
                  Delete
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      );
    } else {
      return null;
    }
  }

  return (
    <div style={{ height: "calc(100vh - 48px)" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Start</StyledTableCell>
              <StyledTableCell align="right">End</StyledTableCell>
              <StyledTableCell align="right">Duration</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>

          <Jtable />
        </Table>
      </TableContainer>
      {open && (
        <SimpleModal
          insert={insert}
          toclose={setOpen}
          update={updaterecord}
          create={createrecord}
        />
      )}
      {loading && <Loading />}
      <Fab
        color="primary"
        aria-label="add"
        style={{ bottom: 50, right: 50, position: "fixed" }}
        onClick={() => {
          setInsert({ new: true });
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
  async function deleterecord(id, t, dur) {
    setLoading(true);
    let val = await findId(t);
    let lol = await totals.get();
    let stuff = lol.data();
    stuff[val["id"]][0] -= dur;
    stuff[val["id"]][1] =
      Math.ceil((stuff[val["id"]][0] / 60 / 60) * val["price"] * 100) / 100;
    await totals.set(stuff);
    await messagesRef.doc(id).delete();
  }

  async function updaterecord(insert) {
    setOpen(false);
    setLoading(true);
    const t = firebase.firestore.Timestamp.fromDate(
      new Date(insert.start.valueOf())
    );

    let old = await messagesRef.doc(insert.id).get();
    old = old.data();
    let val1 = await findId(old.start.seconds);
    let val = await findId(t.seconds);
    let lol = await totals.get();
    let stuff = await lol.data();
    stuff[val1["id"]][0] -= old.duration;
    stuff[val1["id"]][1] =
      Math.ceil((stuff[val1["id"]][0] / 60 / 60) * val1["price"] * 100) / 100;
    stuff[val["id"]][0] += insert.duration.asSeconds();
    stuff[val["id"]][1] =
      Math.ceil((stuff[val["id"]][0] / 60 / 60) * val["price"] * 100) / 100;

    totals.set(stuff);
    await messagesRef.doc(insert.id).update({
      duration: insert.duration.asSeconds(),
      start: t,
      name: insert.name,
    });
  }

  async function createrecord(insert) {
    setOpen(false);
    setLoading(true);
    const t = firebase.firestore.Timestamp.fromDate(
      new Date(insert.start.valueOf())
    );

    let lol = await totals.get();
    let stuff = lol.data();

    let val = await findId(t.seconds);
    stuff[val["id"]][0] += insert.duration.asSeconds();
    stuff[val["id"]][1] =
      Math.ceil((stuff[val["id"]][0] / 60 / 60) * val["price"] * 100) / 100;
    totals.set(stuff);
    await messagesRef.add({
      name: insert.name,
      start: t,
      uid: props.auth.currentUser.uid,
      duration: insert.duration.asSeconds(),
      type: "record",
    });
  }

  async function findId(t) {
    const timeslots = await firestore
      .collection("myvideovault")
      .doc("settings")
      .collection("pricepoints")
      .get();
    let life = {};
    let count = 0;
    let val = {};
    timeslots.forEach((doc) => {
      life[doc.id] = doc.data();
      count++;
    });

    for (let i = 0; i < count; i++) {
      if (life[i].start) {
        if (t < life[i].start.seconds) {
          break;
        }
      }
      val["id"] = i;
      val["price"] = life[i].price;
    }
    return val;
  }
}
