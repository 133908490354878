import React, { useState } from "react";
import DisplayComponent from "./Components/DisplayComponent";
import BtnComponent from "./Components/BtnComponent";
import "./App.css";

function Sw(props) {
  const [time, setTime] = useState({s: props.time.s, m: props.time.m, h: props.time.h });
  const [status, setStatus] = useState(0);
  // Not started = 0
  // started = 1
  // stopped = 2

  const start = () => {
    setStatus(1);
    setInterval(run, 1000);
  };

  var 
    updatedS = time.s,
    updatedM = time.m,
    updatedH = time.h;

  const run = () => {
    if (updatedM === 60) {
      updatedH++;
      updatedM = 0;
    }
    if (updatedS === 60) {
      updatedM++;
      updatedS = 0;
    }
    updatedS++;
    return setTime({ s: updatedS, m: updatedM, h: updatedH });
  };

  if (status !== 1) {
    start();
  }

  return (
    <div className="main-section">
      <div className="clock-holder">
        <div className="stopwatch">
          <DisplayComponent time={time} />
          <BtnComponent
            status={status}
            resume={() => console.log("resume")}
            reset={() => console.log("reset")}
            stop={props.stop}
            start={start}
          />
        </div>
      </div>
    </div>
  );
}

export default Sw;
