import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import moment from "moment";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "90%",
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40%",
  },
  cancel: {
    height: "30px",
    float: "left",
  },
  submit: {
    height: "30px",
    float: "right",
  },
  butgroup: {
    width: "90%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function PaymentModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.toclose(false);
  };

  const [values, setValues] = React.useState({
    received: moment(),
    hours: 1,
    amount: 10,
  });

  const handleUpdate = () => {
    props.create(values);
  };
  const handleChange = (event) => {
    if (event.target.name === "received") {
      let start = event.target.value;
      start = moment(start);
      if (start.isValid()) {
        setValues({
          ...values,
          [event.target.name]: start,
        });
      }
    } else {
      if (parseFloat(event.target.value) > 0) {
        setValues({
          ...values,
          [event.target.name]: parseFloat(event.target.value),
        });
      }
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.container} noValidate>
        <TextField
          id="datetime-local"
          name="received"
          label="Date Received"
          type="date"
          value={values.received.format("yyyy-MM-DD")}
          onChange={handleChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <br />
        <TextField
          label="Hours Worked"
          value={values.hours}
          onChange={handleChange}
          name="hours"
          id="hour-input"
          className={classes.textField}
          type="number"
        />
        <br />
        <br />
        <br />
        <TextField
          label="Payment Recieved"
          value={values.amount}
          onChange={handleChange}
          name="amount"
          id="lol-input"
          className={classes.textField}
          type="number"
        />
        <br />
        <br />
        <br />
        <div className={classes.butgroup}>
          <Button className={classes.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={classes.submit} onClick={handleUpdate}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
