import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Button } from "@material-ui/core";
import moment from "moment";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "90%",
  },
  textField2: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "40%",
  },
  cancel: {
    height: "30px",
    float: "left",
  },
  submit: {
    height: "30px",
    float: "right",
  },
  butgroup: {
    width: "90%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={duration}
      placeholder="hh:mm:ss"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.toclose(false);
  };

  const input = props.insert;

  let create = input.new;

  if (create) {
    input.id = "New Task";
    input.name = "New Task";
    input.duration = moment.duration(10, "minutes");
    input.end = moment();
    input.start = moment().subtract(input.duration);
  }

  const [values, setValues] = React.useState({
    id: input.id,
    name: input.name,
    start: input.start,
    end: input.end,
    duration: input.duration,
  });

  const handleUpdate = () => {
    if (create) {
      props.create(values);
    } else {
      props.update(values);
    }
  };
  const handleChange = (event) => {
    if (event.target.name === "duration") {
      let val = event.target.value;
      let hours = val.substring(0, 2) || 0;
      let minutes = val.substring(2, 4) || 0;
      let seconds = val.substring(4, 6) || 0;
      hours = parseInt(hours);
      minutes = parseInt(minutes);
      seconds = parseInt(seconds);
      minutes += hours * 60;
      seconds += minutes * 60;
      if (seconds > 0) {
        let stuff = values;
        stuff.duration = moment.duration(seconds, "seconds");
        stuff.end = moment(stuff.start.valueOf()).add(stuff.duration);
        setValues({ ...stuff });
      }
    } else if (event.target.name === "end") {
      let end = event.target.value;
      end = moment(end);
      if (end.isAfter(values.start)) {
        let stuff = values;
        stuff.duration = moment.duration(end.diff(values.start));
        stuff.end = end;
        setValues({ ...stuff });
      }
    } else if (event.target.name === "start") {
      let stuff = values;
      let start = event.target.value;
      stuff.start = moment(start);
      stuff.end = moment(stuff.start.valueOf()).add(stuff.duration);
      if (stuff.start.isValid()) {
        setValues({ ...stuff });
      }
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <form className={classes.container} noValidate>
        <TextField
          id="text"
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <br />
        <TextField
          id="datetime-local"
          name="start"
          label="Start"
          type="datetime-local"
          value={values.start.format("yyyy-MM-DDTHH:mm:ss")}
          onChange={handleChange}
          className={classes.textField}
          InputProps={{ inputProps: { step: 1 } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <br />
        <br />
        <TextField
          id="date-local"
          name="end"
          label="End"
          type="datetime-local"
          value={values.end.format("yyyy-MM-DDTHH:mm:ss")}
          onChange={handleChange}
          className={classes.textField}
          InputProps={{ inputProps: { step: 1 } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br /> <br />
        <br />
        <TextField
          label="Duration"
          value={values.duration.format("hhmmss", {
            trim: false,
          })}
          onChange={handleChange}
          name="duration"
          id="formatted-duration-input"
          className={classes.textField}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <br />
        <br />
        <br />
        <div className={classes.butgroup}>
          <Button className={classes.cancel} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={classes.submit} onClick={handleUpdate}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

function limit(val, min, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }
  if (min.length === 1) {
    min = "0" + min;
  }
  if (val.length === 2) {
    if (Number(val) < min) {
      val = min;

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function duration(val) {
  let hours = limit(val.substring(0, 2), "0", "23");
  let minutes = limit(val.substring(2, 4), "0", "59");
  let seconds = limit(val.substring(4, 6), "0", "59");
  while (hours.length < 2) {
    hours = hours + "0";
  }
  while (minutes.length < 2) {
    minutes = minutes + "0";
  }
  while (seconds.length < 2) {
    seconds = seconds + "0";
  }
  return hours + ":" + minutes + ":" + seconds;
}
